:local(.container) {
  background-image: url('/newImages/banner_perfil_invitacion.png');
  background-size: contain;
  background-position: left bottom;
  background-repeat: no-repeat;
  display: flex;
  align-items: end;
  justify-content: end;
  text-align: center;
  height: 800px;
  width: 100%;
  color: #003058;
  align-items: center;
  margin: 70px 0 0;
}

:local(.content) h1 {
  font-size: 50px;
  margin: 0 0 15px;
}

:local(.content) {
  margin: 0 30% 15%;
}

@media (max-width: 1400px) {
  :local(.container) {
    height: 600px;
  }
  :local(.content) {
    margin: 0 15% 15%;
  }
}

@media (max-width: 1023px) {
  :local(.container) {
    height: 450px;
  }
  :local(.content) {
    margin: 0 20% 20%;
  }
  :local(.content) h1 {
    font-size: 35px;
  }
}

@media (max-width: 850px) {
  :local(.content) {
    margin: 0 10% 10%;
  }
  :local(.content) h1 {
    font-size: 20px;
  }
}
