h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin: 0;
}

h1 {
  font-size: 25px;
}

h3 {
  font-size: 20px;
}

a {
  text-decoration: none;
  color: #003058;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}
