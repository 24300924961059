:local(.bannerContainer) {
  background-image: url('/newImages/banner_perfil_invitacion.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center bottom;

  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-top: 100px;
}

:local(.bannerImg) {
  position: relative;
  opacity: 0%;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

:local(.content) {
  position: relative;
  right: 15%;
  top: 50%;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
  margin-top: 150px;
}

:local(.bannerText) {
  font-size: 35px;
  font-weight: bold;
  color: #003058;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  :local(.content) {
    margin-top: 50px;
    right: 10%;
  }
  :local(.bannerText) {
    font-size: 25px;
  }
}

@media screen and (max-width: 480px) {
  :local(.content) {
    margin-top: 30px;
    right: 5%;
  }
  :local(.bannerText) {
    font-size: 25px;
  }
}
