/* :local(.container) {
  position: fixed;
  bottom: 210px;
  margin: 20px;
  width: 60px;
  z-index: 100;
  background: #09d261;
  padding: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  composes: buttonShadow from global;
  float: right;
} */
:local(.container) {
  position: absolute;
  margin-top: -50px;
}

:local(.container) img {
  margin-top: -30px;
  margin-right: 25px;
  height: 60px;
  cursor: pointer;
}

:local(.container) img:hover {
  transform: scale(1.05);
}

@media screen and (max-width: 450px) {
  :local(.container) img {
    margin-top: -10px;
    margin-right: 0px;
    height: 60px;
  }
}
