:local(.container) {
  composes: mainAppLayout from global;
}

:local(.WhatsappChat) {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: right;
}
