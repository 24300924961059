:local(.container) {
  padding: 10px;
}

:local(.label) {
  color: #999;
  text-align: right;
}

:local(.edit) {
}
:local(.container) table {
  border-spacing: 0;
}

:local(.container) td {
  padding: 5px;
}

:local(.containerWithTitle) {
  composes: content from global;
  padding: 0 !important;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 20px;
}

:local(.title) {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px 20px;
  background: #fafafa;
}

:local(.titleText) {
  flex: 1;
  font-weight: 700;
}

:local(.edit) {
  color: #2866ff;
  cursor: pointer;
}

:local(.containerWithTitle) :local(.container) {
  padding: 20px;
}
