:local(.container) {
  padding: 100px 0 0;
  min-height: 100vh;
  color: #111;
}

:local(.title) {
  text-align: center;
}

:local(.title) h1 {
  margin: 50px 0;
  font-size: 35px;
  color: #003058;
  font-weight: lighter;
}

:local(.title) img {
  max-width: 100%;
  width: 300px;
}

:local(.infoLine) {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

:local(.infoIcon) img {
  margin-right: 10px;
  height: 25px;
}

:local(.infoText) {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 18px;
  margin: 0;
  font-weight: lighter;
}

:local(.infoText2) {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 18px;
  margin: 0;
  font-weight: lighter;
}

:local(.subtitle) {
  font-family: 'Open Sans', sans-serif;
  color: #0097CC;
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: bold;
}

:local(.iconoHipotecario) {
  display: flex;
  justify-content: center;
}

:local(.iconoHipotecario) img {
  height: 200px;
  margin: 50px 0;
}

:local(.buttonContainer) {
  display: flex;
  justify-content: center;
  margin: 50px 0 0;
}
