.red {
  color: #f43f4f;
}

.orange {
  color: #d45113;
}

.blue  {
  color: #003058;
}

.dark {
  color: #000d22;
}

.primary {
  color: #75c644;
}
