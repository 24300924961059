:local(.container) {
}

:local(.secondaryText) {
  font-size: 14px;
  color: #999;
}

.orion-select__option--is-selected :local(.secondaryText) {
  color: #fff;
}

.orion-select__value-container :local(.mainText) {
  font-size: 16px;
  display: inline-block;
}

.orion-select__value-container :local(.secondaryText) {
  font-size: 16px;
  display: inline-block;
  margin-left: 5px;
}
