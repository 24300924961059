:local(.container) {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 10px;
}

:local(.selected) {
  cursor: pointer;
  flex: 1;
  min-width: 120px;
  text-align: center;
  margin: 5px;
  font-size: 14px;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  composes: primaryBorderColor from global;
  padding: 15px 5px;
}

:local(.option) {
  composes: selected;
  border-color: #eee !important;
}

:local(.icon) {
}

:local(.label) {
  margin-top: 5px;
}
