:local(.container) {
  /* background: #003da5; */
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

:local(.wrap) {
  align-self: center;
  display: flex;
  margin: auto;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
}

:local(.container) img {
  max-width: 100%;
}

:local(.container) .col-xs-6 {
  display: flex;
  align-items: center;
  justify-content: center;
}
:local(.title) h1 {
  color: #0097CC;
  font-size: 35px;
  margin: 15px;
}

:local(.content) {
  text-align: left;
}

:local(.content) h2 {
  color: #003058;
  margin-bottom: 20px;
  font-size: 20px;
}

:local(.content) p {
  margin: 20px 20px 40px;
  font-size: 20px;
}

:local(.content) a {
  color: #0097CC;
  text-decoration: none;
}
:local(.content) a:visited {
  color: #0097CC;
}

@media (max-width: 680px) {
  :local(.content) h1 {
    font-size: 20px;
  }
}
