:local(.image) {
  background-size: contain;
  background-position: left bottom;
  background-repeat: no-repeat;
  flex: 1;
}

:local(.container) {
  padding: 150px 0 0;
  min-height: 100vh;
}

:local(.content) {
  composes: content from global;
  max-width: 500px;
  margin: 0 auto;
}

:local(.stepInfoContainer) .row > div {
  flex: 1;
  display: flex;
  min-height: 500px;
}

:local(.stepInfo) {
  display: flex;
  flex-direction: row;
  max-width: 600px;
}

:local(.stepInfo) h1 {
  font-size: 20px;
  color: #003058;
}

:local(.stepInfo) p {
  font-size: 20px;
}

:local(.imageNeed) {
  composes: image;
  background-image: url('/images/weneedyou.png');
}
:local(.imageOk) {
  composes: image;
  background-image: url('/newImages/banner_referido_enviado.png');
}

:local(.check) {
  width: 30px;
  height: 30px;
  margin-right: 12px;
}
