:local(.container) {
  padding: 100px 0 0;
  /* min-height: 100vh; */
  color: #111;
}

:local(.title) {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

:local(.title) h1 {
  color: #003058;
  margin: 80px 0 80px;
  max-width: 500px;
  font-size: 35px;
  align-self: center;
  font-weight: lighter;
}

:local(.title) img {
  max-width: 100%;
  width: 300px;
}

:local(.step) {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  text-align: center;
  align-items: center;
}

:local(.step) img {
  width: 300px;
  max-width: 100%;
}

:local(.step) p {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 18px;
  margin: 0;
  max-width: 340px;
}

:local(.infoContainer) {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px 0 0;
}

:local(.info) {
  display: flex;
  margin: 15px 0;
  text-align: left;
  align-items: center;
  width: 100%;
  max-width: 850px;
}

:local(.info) img {
  width: 25px;
  margin-right: 10px;
  align-self: flex-start;
}

:local(.info) p {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 20px;
  margin: 0;
}

:local(.buttonContainer) {
  display: flex;
  justify-content: center;
  margin: 50px 0 0;
}

:local(.buttons) {
  padding: 20px 0;
  text-align: center;
}

:local(.video) {
  text-align: center;
  margin-top: 30px;
}
