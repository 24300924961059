:local(.container) {
  text-align: center;
  padding: 100px 0;
}

:local(.content) {
  max-width: 700px;
  margin: 0 auto 100px;
  padding: 20px;
  border-radius: 5px;
  background-color: #f6f6f6;
}

:local(.content) h1 {
  font-size: 35px;
  color: #003058;
  margin: 0 0 30px;
}

:local(.content) h3 {
  font-size: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}

:local(.content2) h1 {
  font-size: 35px;
  color: #003058;
  margin: 15px 0 30px;
}

:local(.content2) h3 {
  font-size: 20px;
  color: #0097CC;
  margin: 15px 0 30px;
}

:local(.content2) p {
  font-size: 20px;
  margin: 60px 30px;
  color: #003058;
}

:local(.infoContainer) {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 30px;
}

:local(.info) {
  display: flex;
  margin: 15px 30px;
  text-align: left;
  align-items: center;
  width: 100%;
  max-width: 850px;
}

:local(.info) img {
  width: 25px;
  margin-right: 10px;
  align-self: flex-start;
}

:local(.info) p {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 20px;
  margin: 0;
}

:local(.map) {
  display: flex;
  flex-wrap: wrap;
  margin: 150px auto 0;
  align-items: center;
  justify-content: space-around;
}

:local(.num) {
  background-color: #003058;
  color: white;
  border-radius: 15px;
  padding: 1px;
  width: 22px;
  text-align: center;
}

:local(.aa) {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
}

:local(.box) {
  width: 250px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  justify-content: space-around;
}

:local(.box) p {
  color: #003058;
  font-size: 20px;
}

:local(.box) img {
  max-width: 150px;
  margin: 0;
}

:local(.button) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 330px;
  border-radius: 5px;
  cursor: pointer;
  color: #111;
  padding-bottom: 20px;
}

:local(.button):hover {
  background: rgba(0, 0, 0, 0.2);
}

:local(.button) h2 {
  color: #003058;
  font-size: 30px;
  margin-top: -30px;
  margin-bottom: 20px;
}

:local(.imageContainer) {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

:local(.imageContainer) img {
  max-width: 100%;
}

:local(.container) .os_button_container:last-child {
  margin-right: 0;
}

/* @media (max-width: 1216px) {
  :local(.box) {
    margin: 20px auto;
  }
} */

@media (max-width: 768px) {
  :local(.box) {
    margin: 20px auto;
  }
  :local(.info) p {
    font-size: 20px;
  }
}
