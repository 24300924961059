:local(.container) {
  left: 0;
  right: 0;
  top: 0;
  z-index: 100;
  padding-bottom: 7px;
}

:local(.logoBoth) {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:local(.logoBothMCL) {
  height: 70px;
  margin-right: 15px;
}

:local(.logoBothConsorcio) {
  height: 30px;
}

:local(.logoConsorcio) {
  display: flex;
  align-items: center;
}

:local(.logoConsorcio) img {
  height: 45px;
}

:local(.navbarInner) {
  display: flex;
}

:local(.spacer) {
  flex: 1;
}

:local(.user) {
  padding: 10px 0;
}
