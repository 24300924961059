:local(.container) {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  width: 250px;
  min-width: 250px;
  height: 500px;
  border: 1px solid #d9d9d6;
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}

:local(.image img) {
  width: 150px;
}

:local(.check) {
  height: 20px;
  width: 20px;
  margin: 20px 5px 0 0;
}

:local(.description) {
  text-align: left;
}

:local(.descriptionItem) {
  color: #53565a;
  display: flex;
  flex-direction: row;
}

:local(.foot h1) {
  color: #003058;
  font-size: 22px;
}

:local(a:link) {
  color: #53565a;
  text-decoration: none;
}

:local(a:visited) {
  color: #53565a;
  text-decoration: none;
}

:local(.link:hover) {
  color: #53565a;
  text-decoration: none;
  text-shadow: 1px 2px 5px #d9d9d6;
  position: relative;
  bottom: 1px;
}

:local(.link:active) {
  color: #53565a;
  text-decoration: none;
  text-shadow: none;
  transition: all 1s;
  position: relative;
  top: 1px;
}
