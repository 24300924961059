:local(.container) {
  composes: content from global;
}

:local(.title) {
  font-weight: bold;
}

:local(.status) {
  color: #999;
  margin-top: 10px;
}

:local(.comments) {
  margin-top: 10px;
}
