:local(.container) {
  border-top: 1px solid #eee;
  padding-top: 40px;
  margin-bottom: 20px;
  margin-top: 40px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

:local(.containerNoBorder) {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  align-content: center;
}

:local(.title) {
  margin-bottom: 10px;
}

:local(.description) {
  color: #7d7d7d;
}

:local(.childrenNoBorder) {
  margin-top: 40px;
}

:local(.children) {
  margin-top: 40px;
}
