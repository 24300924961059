:local(.container) {
  padding: 150px 0 50px;
  min-height: 100vh;
  color: #111;
}

:local(.title) {
  text-align: center;
}

:local(.title) h1 {
  margin-top: 20px;
  font-size: 35px;
  margin-bottom: 20px;
}

:local(.title) img {
  max-width: 100%;
  width: 300px;
}

:local(.questions) {
  margin-top: 30px;
  padding: 40px;
}

:local(.questions) h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #003058;
}

:local(.questions) p {
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: bold;
}

:local(.buttons) {
  text-align: center;
}
