:local(.container) {
  padding: 20px 0;
  background-color: #003058;
  color: #fff;
}

:local(.icons) {
  display: flex;
  align-items: center;
}
:local(.iconMCL) {
  height: 50px;
  margin-right: 20px;
}
:local(.iconConsorcio) {
  height: 30px;
}

:local(.links) a {
  display: inline-block;
  color: #fff;
  margin-bottom: 5px;
  text-align: right;
}

:local(.links) {
  text-align: right;
}

:local(.pya) {
  justify-content: flex-end;
}

:local(.pp) {
  border-right: 2px solid #fff;
  margin-right: 20px;
  padding-right: 20px;
}

:local(.iconSocial) {
  height: 30px;
  margin-left: 12px;
  margin-top: 10px;
  margin-bottom: 15px;
}

:local(.linksFooter) {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 5px;
}

@media screen and (max-width: 450px) {
  :local(.pp) {
    border-right: none;
    margin-right: 0;
    padding-right: 0;
  }

  :local(.pya) {
    display: flex;
    flex-direction: column;
  }
}
