.mainAppLayout .os-tabs-container {
  margin-bottom: 30px;
}

.mainAppLayout .os-tabs-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

.mainAppLayout .os-tabs-item a {
  color: #53565a;
  background-color: #d9d9d6;
  width: 150px;
  height: 33px;
  justify-content: center;
  font-weight: lighter;
}

.mainAppLayout .os-tabs-item.os-tabs-itemActive a {
  color: #fff;
  background-color: #0097CC;
  width: 150px;
  height: 33px;
  justify-content: center;
  border-bottom: none;
}

.mainAppLayout .os-tabs-item {
  margin-right: 5px;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .mainAppLayout .os-tabs-inner {
    justify-content: inherit;
  }
}

@supports (padding: max(0px)) {
  .mainAppLayout .os-tabs-inner {
    padding-left: calc(20px + env(safe-area-inset-left));
    padding-right: calc(20px + env(safe-area-inset-right));
  }
}
