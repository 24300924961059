:local(.header) {
  padding-bottom: 10px;
}

:local(.container) {
  margin-top: 120px;
}

:local(.titleDiv) {
  text-align: center;
  margin-bottom: 100px;
}
