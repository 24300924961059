:local(.container) {
  padding: 150px 0 50px;
  min-height: 100vh;
}

:local(.content) {
  composes: content from global;
  max-width: 500px;
  margin: 0 auto;
}

:local(.stepInfoContainer) .row > div {
  flex: 1;
  display: flex;
  min-height: 500px;
}

:local(.stepInfo) {
  max-width: 600px;
}

:local(.stepInfo) h1 {
  font-size: 20px;
  color: #003058;
}

:local(.stepInfo) p {
  font-size: 20px;
}

:local(.image) {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
}

:local(.imageNeed) {
  composes: image;
  background-image: url('/images/weneedyou.png');
}
:local(.imageOk) {
  composes: image;
  background-image: url('/images/weareok.png');
}

:local(.tos) {
  font-size: 14px;
}
