:local(.image) {
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  flex: 1;
}

:local(.container) {
  padding: 150px 0 50px;
  min-height: 100vh;
  composes: image;
  background-image: url('/newImages/banner_referido_enviado.png');
}

:local(.content) {
  composes: content from global;
  max-width: 500px;
  margin: 0 auto;
}

:local(.stepInfoContainer) .row > div {
  flex: 1;
  display: flex;
  min-height: 500px;
}

:local(.stepInfo) {
  max-width: 600px;
}

:local(.stepInfo) h1 {
  font-size: 20px;
}

:local(.stepInfo) p {
  font-size: 20px;
}

:local(.imageNeed) {
  composes: image;
  background-image: url('/images/weneedyou.png');
}
