:local(.image) {
  background-size: contain;
  background-position: left bottom;
  background-repeat: no-repeat;
  flex: 1;
}

:local(.containerSuccess) {
  composes: image;
  background-image: url('/newImages/banner_referido_enviado.png');
  padding: 150px 0 0;
  min-height: 100vh;
}

:local(.stepInfo) {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

:local(.stepInfo) h1 {
  font-size: 20px;
  color: #003058;
}

:local(.stepInfo) p {
  font-size: 20px;
}
