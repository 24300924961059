.orion_button,
.orion_button:active,
.orion_button:hover {
  user-select: none;
  border-radius: 5px;
  text-transform: none;
  letter-spacing: 0;
  height: 30px;
  text-transform: uppercase;
  padding-top: 3px;
  padding-bottom: 3px;
}

.orion_danger,
.orion_danger:active,
.orion_danger:hover {
  background-color: #003058;
  margin-top: 7px;
}

.orion_primary,
.orion_primary:active,
.orion_primary:hover {
  background-color: #003058;
  margin-top: 7px;
}

.orion_refer,
.orion_refer:active,
.orion_refer:hover {
  background-color: #003058;
  margin-top: 7px;
}
