:local(.container) {
  display: flex;
  width: 100%;
  font-size: 20px;
  margin: 50px 0 0;
  font-weight: lighter;
  color: #0097CC;
}

:local(.container) p {
  color: #0097CC;
  text-decoration: none;
}

:local(.container) a {
  color: #000000;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

:local(.container) img {
  width: 35px;
  height: 35px;
  margin: 0 5px;
}

@media (max-width: 500px) {
  :local(.container) a {
    flex-direction: column;
  }
  :local(.container) img {
    opacity: 0;
  }
  :local(.container) p {
    margin: 0 0 -20px;
  }
}
