:local(.container) {
}

:local(.imageContainer) {
  display: inline-block;
  composes: smallShadow from global;
  margin-bottom: 10px;
  margin-right: 10px;
}

:local(.image) {
  height: 90px;
  margin-bottom: -4px;
}

:local(.colors) {
  padding: 5px;
  overflow: hidden;
  font-size: 12px;
}
