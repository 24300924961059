:local(.container) {
  position: relative;
}

:local(.digit) {
  position: absolute;
  bottom: 0;
  border: 1px solid transparent;
  padding: 10px;
  color: #777;
}

:local(.container) input {
  padding-left: 45px;
}
