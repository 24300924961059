.title {
  font-family: 'Geograph-light', sans-serif;
  font-size: 25px;
  padding-top: 20px;
  margin-bottom: 20px;
}

h1, h2{
  font-family: 'Geograph-light', sans-serif;
  font-weight: 300;
}