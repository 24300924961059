:local(.hero) {
  display: flex;
  align-items: flex-start;
  height: 600px;
  width: 100%;
  text-align: center;
  background-image: url('/newImages/banner_home.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  color: #003058;
  /* margin-top: 50px; */
}

:local(.content) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

:local(.text) {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  max-width: 500px;
  height: 100%;
  margin: auto 10%;
}

:local(.hero) h1 {
  margin-top: 60px;
  font-size: 35px;
}

:local(.hero) h2 {
  color: #0097CC;
  font-size: 35px;
}

:local(.hero) p {
  font-size: 20px;
  max-width: 400px;
}

@media (max-width: 1400px) {
  :local(.hero) {
    height: 500px;
  }
  :local(.text) {
    margin: 0 50px;
  }
}

@media (max-width: 1023px) {
  :local(.hero) {
    height: 600px;
    background-image: none;
  }
  :local(.text) {
    max-width: 85%;
    margin: auto;
  }
  :local(.text) p {
    max-width: 100%;
  }
}

:local(.container) iframe {
  box-shadow: 0 2px 4px rgba(50, 50, 93, 0.1);
  border-radius: 5px;
}
