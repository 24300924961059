:local(.container) {
  padding: 100px 0 0;
  min-height: 100vh;
  color: #111;
}

:local(.title) {
  text-align: center;
}

:local(.title) h2 {
  margin: 80px 0;
  font-weight: lighter;
  color: #003058;
  font-size: 35px;
}

:local(.title) img {
  max-width: 100%;
  width: 300px;
}

:local(.infoLine) {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

:local(.infoIcon) img {
  margin-right: 10px;
  height: 25px;
}

:local(.infoText) {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 18px;
  margin: 0;
  font-weight: lighter;
}

:local(.infoText2) {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 18px;
  margin: 0;
  font-weight: lighter;
}

:local(.subtitle) {
  font-family: 'Open Sans', sans-serif;
  color: #003058;
  font-size: 18px;
  margin: 0 0 20px;
  font-weight: bold;
}

:local(.regins) {
  margin: 100px 0;
}

:local(.regins) h3 {
  color: #75c644;
  font-size: 20px;
  text-align: center;
}

:local(.regins) p {
  font-family: 'Open Sans', sans-serif;
  color: #53565a;
  font-size: 18px;
}

:local(.buttonContainer) {
  display: flex;
  justify-content: center;
  margin: 50px 0 0;
}
