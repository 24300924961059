:local(.container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:local(.formTitle) {
  color: #003058;
}

:local(.contentContainer) {
  width: 80%;
  justify-content: center;
}

:local(.titleDiv) {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #53565a;
}

:local(.dropdown) {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  margin-bottom: 5px;
}

:local(.check) {
  width: 25px;
  height: 25px;
  margin-right: 8px;
  margin-bottom: -5px;
}

:local(.recomendationList) {
  margin: 10px;
  display: flex;
  flex-direction: column;
}

:local(.recomendationItem) {
  color: #53565a;
  margin: 5px;
  align-items: center;
  justify-content: center;
}

:local(.cards) {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
